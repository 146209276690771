<template>
    <div class="contact-form-area pd-top-100 pd-bottom-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 mt-4 mt-lg-0">
                    <div class="contact-form-inner-wrap" style="background: rgba(100, 62, 125, 0.08);" v-for="publish in audio" :key="publish.id">
                        <div class="media-body details align-self-center pointer ">
                                <h6>( {{ publish.CategroyAR }} ) {{ publish.nameAR }}</h6>
                                <p>{{ publish.SingerNameAR }}</p>
                            </div>
                         <!-- <audio controls autoplay controlsList="nodownload" style="width: 100%;"> -->
                             <audio id="myaudio" style="border:10px solid #7b6199; border-radius: 50px; width: 100%;" controls autoplay controlsList="nodownload">
                            <!-- <source src="horse.ogg" type="audio/ogg"> -->
                                <source :src="publish.mp3URL" type="audio/mpeg">
                                Your browser does not support the audio element.
                        </audio> 
                    </div>
                </div>
                
                <!-- <div class="col-xl-4 col-lg-5">
                    <div class="contact-form-inner">
                        <div class="media single-contact-info" style="margin-bottom: 0px;" v-for="publish in anashed.slice(0,5)" :key="publish.id">
                            <div class="media-left thumb pointer" style="color: #ee27bc;" @click="getSound(publish)">
                                <i class="fas fa-file-audio"></i>
                            </div>
                            <div class="media-body details align-self-center pointer ">
                                <h6>{{ publish.nameAR }}</h6>
                                <p>{{ publish.SingerNameAR }}</p>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <br><br>
            <div class="row">
                <div class="col-xl-12 col-lg-5">
                    <div class="contact-form-inner">
                        <div class="media single-contact-info" style="margin-bottom: 25px;background: rgba(131, 137, 159, 0.07) none repeat scroll 0% 0%;" v-for="publish in anashed.slice(0,12)" :key="publish.id">
                            <div class="media-left thumb pointer" style="color: #ee27bc;" @click="getSound(publish)">
                                <!-- <i class="fas fa-phone"></i> -->
                                <i class="fas fa-file-audio"></i>
                            </div>
                            <div class="media-body details align-self-center pointer" @click="getSound(publish)">
                                <h6>{{ publish.nameAR }}</h6>
                                <p>{{ publish.SingerNameAR }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { HTTP } from '@/Api/http-common';
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
export default {
   name: 'Sounds',
   props: {
      id: String
   },
   async setup(props) {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()

        const audio = ref([]);
        const anashed = ref([]);       
        try {
            await HTTP.get("audio.php?id=" + props.id).then((res) => {
                audio.value = res.data.readPost; 
            });
        } catch (err) {
           console.log(err)
        }
        try {
            await HTTP.get("IslamicContent.php?catId=" + cookie.getCookie("cat_audio") + "&LIMIT=8").then((res) => {
                anashed.value = res.data.getPost; 
            });
        } catch (err) {
           console.log(err)
        }
        const getSound = (publish) => {
            router.push({ name: "Sound", params: { id: publish.id } });
        };
       
      return {
        audio,
        anashed,
        getSound
      }
    },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.contact-form-inner {
    box-shadow: 0px 10px 30px rgba(91, 17, 94, 0.57);
    border-radius: 10px;
}

.single-contact-info {
    box-shadow: 0px 10px 30px rgba(203, 182, 204, 0.57);
    border-radius: 20px;
}
/* audio {height:200px; width:300px; background:yellow;}
audio:hover {transform: scale(1.1);filter: drop-shadow(2px 3px 3px #333);}
audio::-webkit-media-controls-play-button{
background-color:green;} */
</style>